import React, { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectedRootOffice } from "../../../../../store/ducks/officeView.duck";
import styled from "styled-components/macro";
import {
  OfficeWhereaboutsView,
  selectActivePeriod,
  setActivePeriod,
} from "../../../../../store/ducks/editMovements.duck";
import { Period } from "../../../../../models/movements.models";
import { tablet } from "../../../../UI/atoms/MediaQueries";
import { selectAllOfficeEntities } from "../../../../../store/ducks/advanceHotDeskingSetup.duck";
import { ToggleableOutlineButton } from "../../../../UI/atoms/buttons/ToggleableOutlineButton";
import { SingleOfficeUsageView } from "./SingleOfficeUsageView";
import { OfficeEntity } from "../../../../../services/AdvanceHotDeskingService";
import { AreaAndDeskUsageView } from "./AreaAndDeskUsageView";

enum OfficeConfigurationType {
  None, Office, Floors, AreasAndDesks
}

export function OfficeUsageView() {
  const dispatch = useDispatch();
  const activePeriod = useSelector(selectActivePeriod);
  const rootOffice = useSelector(selectedRootOffice);
  const allOffices = useSelector(selectAllOfficeEntities);
  const activeView = OfficeWhereaboutsView.Office;

  const officeConfigurationType = useMemo(() => {
    if (rootOffice && allOffices.length > 0) {
      const rootOfficeChildren = allOffices.filter((o: OfficeEntity) => o.parentId === rootOffice.id);
      if (rootOfficeChildren.length === 0) {
        return OfficeConfigurationType.Office;
        // Maybe add floors in
      } else {
        return OfficeConfigurationType.AreasAndDesks;
      }
    }
    return OfficeConfigurationType.None;
  }, [rootOffice, allOffices]);

  return (
    <OfficesUsageListViewContainer data-test="office-members-container">
      <ViewControls>
        <ToggleableOutlineButton fullWidth={true} text="button.all-day" dataTest={"all-day-button"}
                                 click={() => dispatch(setActivePeriod(Period.AllDay))}
                                 isActive={activePeriod === Period.AllDay && activeView === OfficeWhereaboutsView.Office}/>
        <ToggleableOutlineButton fullWidth={true} text="button.am-only" dataTest={"am-day-button"}
                                 click={() => dispatch(setActivePeriod(Period.AM))}
                                 isActive={activePeriod === Period.AM && activeView === OfficeWhereaboutsView.Office}/>
        <ToggleableOutlineButton fullWidth={true} text="button.pm-only" dataTest={"pm-day-button"}
                                 click={() => dispatch(setActivePeriod(Period.PM))}
                                 isActive={activePeriod === Period.PM && activeView === OfficeWhereaboutsView.Office}/>

        {(!!rootOffice?.carParkCapacity || !!rootOffice?.hasCarParkPlan) && <>

          <ToggleableOutlineButton fullWidth={true} text="whereabouts.parking"
                                   click={() => {}}
                                   isActive={false}/>
                                   {/*click={() => dispatch(setActiveView(OfficeWhereaboutsView.CarPark))}*/}
                                   {/*isActive={activeView === OfficeWhereaboutsView.CarPark}/>*/}
        </>}

      </ViewControls>
      <OfficesListContainer>

        {officeConfigurationType === OfficeConfigurationType.Office && <SingleOfficeUsageView office={rootOffice} isVisible={true} />}
        {officeConfigurationType === OfficeConfigurationType.AreasAndDesks && <AreaAndDeskUsageView />}
        {/*{activeView === OfficeWhereaboutsView.CarPark ? <>*/}
        {/*  <OfficeCarParkingList />*/}
        {/*</> : <>*/}
        {/*  {officeConfigurationType === OfficeConfigurationType.Office && <SingleOfficeUsageView office={rootOffice} isVisible={true} />}*/}
        {/*  {officeConfigurationType === OfficeConfigurationType.AreasAndDesks && <AreaAndDeskUsageView />}*/}
        {/*</>}*/}


      </OfficesListContainer>
    </OfficesUsageListViewContainer>
  )
}

const OfficesUsageListViewContainer = styled.div`
  padding: 8px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-between;
  @media (${tablet}) {
    padding: 24px 48px 100px 24px;
    flex-direction: row;
  }
`

const ViewControls = styled.div`
  padding: 8px 0;
  display: flex;
  width: 100%;
  margin-right: 24px;
  margin-bottom: 16px;
  & > * {
    flex: 1;
  }
  @media (${tablet}) {
    padding: 8px 16px;
    width: 180px;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;
    & > * {
      flex: unset;
      margin-bottom: 16px;
      width: 100%;
    }
  }
`

const OfficesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
