import React, { useEffect } from 'react';
import PrivateRoute from '../../routing/PrivateRoute';
import { Switch, useLocation } from 'react-router';
import '../settings/user-management.scss';
import { useDispatch } from "react-redux";
import TeamSearchResultsPage from "../team-movements/TeamSearchResultsPage";
import { app } from "@microsoft/teams-js";
import { isInTeams } from "../../../utils/TeamsUtils";
import TagMovementsPage from "../team-movements/TagMovementsPage";
import { WithOfficeUsagesLoader } from "../../../hooks/WithOfficeUsagesLoader";
import SingleTeamViewPage from "../team-movements/SingleTeamViewPage";
import { RoomFinderPage } from "../meeting-rooms/pages/RoomFinderPage";
import { RoomBookings } from "../meeting-rooms/pages/RoomBookings";
import { AddUsersPage } from "../settings/pages/manage-users-page-v2/AddUsersPage";
import { WorkPlannerPage } from "../wall-planner/WorkPlannerPage";
import { OfficeFloorMapView } from "../company-movements/components/OfficeFloorMapView";
import { DailyOfficeListView } from "../company-movements/components/daily-office-list-view/DailyOfficeListView";
import { CompanyTeamView } from "../company-movements/components/CompanyTeamView";
import { CompanyTagsView } from "../company-movements/components/CompanyTagsView";
import MyTeamMovementsPage from "./MyTeamMovementsPage";
import { VisitorBookingView } from "../company-movements/components/visitor-booking/VisitorBookingView";
import { DepartmentsListPage } from "../departments/DepartmentsListPage";
import { DepartmentsUserPage } from "../departments/DepartmentsUserPage";

export default function WhereaboutsRouting() {
  const dispatch = useDispatch();
  const location = useLocation();

  WithOfficeUsagesLoader();

  useEffect(() => {
    if (isInTeams()) {

      app.getContext().then((context: app.Context) => {
        if (context.page.subPageId) {
          const parts = context.page.subPageId.split(':');
          if (parts.length >= 2 && parts[0] === 'search' && !location.pathname.includes(parts[1])) {
            window.location.href = `/whereabouts/team/search/${parts[1]}`;
          } else if (parts.length >= 2 && parts[0] === 'team' && !location.pathname.includes(parts[1])) {
            window.location.href = `/whereabouts/team/${parts[1]}`;
          } else if (parts.length >= 2 && parts[0] === 'tag' && !location.pathname.includes(parts[1])) {
            window.location.href = `/whereabouts/tag/${parts[1]}`;
          }
        }
      })
    }
  }, [location, dispatch]);

  return (
    <Switch>
      <PrivateRoute exact path="/whereabouts/team/add-users" component={AddUsersPage} />
      <PrivateRoute exact path="/whereabouts/team" component={MyTeamMovementsPage}/>
      <PrivateRoute exact path="/whereabouts/team/:teamId" component={SingleTeamViewPage}/>
      <PrivateRoute exact path="/whereabouts/team/search/:teamId" component={TeamSearchResultsPage}/>
      <PrivateRoute exact path="/whereabouts/tag/:tagId" component={TagMovementsPage}/>

      <PrivateRoute exact path="/whereabouts/work-planner" component={WorkPlannerPage}/>
      <PrivateRoute exact path="/whereabouts/floor-planner" component={OfficeFloorMapView}/>
      <PrivateRoute exact path="/whereabouts/office-view" component={DailyOfficeListView}/>
      <PrivateRoute exact path="/whereabouts/visitor-booking/:officeId" component={VisitorBookingView}/>

      <PrivateRoute exact path="/whereabouts/company" component={CompanyTeamView}/>
      <PrivateRoute exact path="/whereabouts/company/all" component={CompanyTeamView}/>
      <PrivateRoute exact path="/whereabouts/company/workgroups" component={CompanyTagsView}/>
      <PrivateRoute exact path="/whereabouts/company/add-users" component={AddUsersPage} />
      <PrivateRoute exact path="/whereabouts/company/team/:teamId" component={SingleTeamViewPage}/>
      <PrivateRoute exact path="/whereabouts/company/tag/:tagId" component={TagMovementsPage}/>
      <PrivateRoute exact path="/whereabouts/company/departments" component={DepartmentsListPage}/>
      <PrivateRoute exact path="/whereabouts/company/departments/:departmentId" component={DepartmentsUserPage}/>

      <PrivateRoute exact path="/whereabouts/meeting-rooms" component={RoomFinderPage}/>
      <PrivateRoute exact path="/whereabouts/meeting-rooms/my-bookings" component={RoomBookings}/>
    </Switch>
  )
}
